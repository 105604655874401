import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RepairingAccordion from "../../Components/Repairing_accord";
import { repair_data_type } from "../../../../models/API_data";
import { useEffect, useState } from "react";
import { repair_creteria_url } from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Global_Component/loader/loader";
import { setrepairingCriteria } from "../../../../features/lms_actions/lmsSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import PaginationControlled from "../../Components/pagination/pagination";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface tab_filter_data_type {
  new: repair_data_type[] | [];
  hold: repair_data_type[] | [];
  appr: repair_data_type[] | [];
  rej: repair_data_type[] | [];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const [page, setPage] = useState(1);
  const [row, setrow] = useState(10);
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={`flex-auto ${
        value !== index && "hidden"
      } w-full h-40 flex flex-col  overflow-auto`}
    >
      {value === index && (
        <>
          <Box className=" w-full">{children}</Box>
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RepairingCriteria() {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const {
    user: { token_id },
    lms: {
      repairingCriteria: { appData, loader },
    },
  } = useSelector((state: RootState) => state);

  const [filtered_data, setfiltered_data] = useState<tab_filter_data_type>({
    new: [],
    hold: [],
    appr: [],
    rej: [],
  });
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(repair_creteria_url, token_id, {
        method: "GET",
      }).then((data) => dispatch(setrepairingCriteria([...data.DATA])));
    }
  }, [token_id]);
  const filter_data = () => {
    return new Promise((resolve, rejects) => {
      const new_data = appData?.filter((item) => item.StageId === 114);
      const hold_data = appData?.filter((item) => {
        if ([115, 116].includes(item.StageId)) {
          return item;
        }
      });
      const appr_data = appData?.filter((item) => {
        if (item.StageId === 113) {
          return item;
        }
      });
      const rej_data = appData?.filter((item) => {
        if ([117, 118, 119].includes(item.StageId)) {
          return item;
        }
      });
      const data = {
        new: new_data,
        hold: hold_data,
        appr: appr_data,
        rej: rej_data,
      };
      if (data) {
        resolve(data);
      } else {
        rejects("error while filtering data");
      }
    });
  };
  useEffect(() => {
    if (appData) {
      filter_data().then((result: any) => {
        setfiltered_data({
          ...filtered_data,
          ...result,
        });
      });
    }
  }, [appData]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          height: "auto",
          paddingTop: "2rem",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            label="All"
            {...a11yProps(0)}
            sx={{
              color: "blue",
              fontSize: "1rem",
              flexShrink: "1",
              minWidth: "40px",
              "@media (max-width:576px)": {
                fontSize: "0.9rem",
              },
            }}
          />
          <Tab
            label="New"
            {...a11yProps(1)}
            sx={{
              color: "#380a5e",
              fontSize: "1rem",
              flexShrink: "1",
              minWidth: "40px",
              "@media (max-width:576px)": {
                fontSize: "0.9rem",
              },
            }}
          />
          <Tab
            label="Onhold"
            {...a11yProps(2)}
            sx={{
              color: "#e0ce2b",
              fontSize: "1rem",
              flexShrink: "1",
              minWidth: "40px",
              "@media (max-width:576px)": {
                fontSize: "0.9rem",
              },
            }}
          />
          <Tab
            label="Approved"
            {...a11yProps(3)}
            sx={{
              color: "green",
              fontSize: "1rem",
              flexShrink: "1",
              minWidth: "40px",
              "@media (max-width:576px)": {
                fontSize: "0.9rem",
              },
            }}
          />
          <Tab
            label="Rejected"
            {...a11yProps(4)}
            sx={{
              color: "red",
              fontSize: "1rem",
              flexShrink: "1",
              minWidth: "40px",
              "@media (max-width:576px)": {
                fontSize: "0.9rem",
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TabContent data={appData || []} loader={loader} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabContent data={filtered_data.new} loader={loader} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabContent data={filtered_data.hold} loader={loader} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <TabContent data={filtered_data.appr} loader={loader} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TabContent data={filtered_data.rej} loader={loader} />
      </TabPanel>
    </Box>
  );
}
interface tab_content_props {
  data: repair_data_type[];
  loader: boolean;
}
const TabContent = ({ data, loader }: tab_content_props) => {
  if (loader) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-8  p-8 w-full h-full ">
        {data.length > 0 ? (
          data.map((item) => (
            <RepairingAccordion item={item} key={item.vehicleId} />
          ))
        ) : (
          <div className="h-full w-full flex justify-center items-center ">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </div>
    );
  }
};
