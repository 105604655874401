import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import {
  repair_data_type,
  repair_stage_menu_api_response,
} from "../../../models/API_data";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { repair_stage_url, update_stage_url } from "../../../API/api";
import { repair_stage_menu_datatype } from "../../../models/API_data";
import showToastMessage from "../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import customFetchWithToken from "../../../API/CustomFetchWtihToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import GroupSelect from "./nested_select/nested_select";

type AppProps = {
  item: repair_data_type;
};
interface stage_options_menu_type {
  label: string;
  id: number;
  groupName: string | null;
}

const RepairingAccordion: React.FC<AppProps> = ({ item }) => {
  const [stage_input, setstage_input] = useState("");

  const [flag, setflag] = useState(false);
  const [edit, setedit] = useState(false);
  let ques_row1 = item.Question.slice(0, 4);
  let ques_row2 = item.Question.slice(4, 8);
  let ques_row3 = item.Question.slice(8, 12);
  let ques_row4 = item.Question.slice(12, 16);
  let total_amt = item.Question.reduce((acc, item) => {
    return acc + item.Cost;
  }, 0);
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);

  const handelEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setedit(true);
  };
  const handelCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setedit(false);
  };
  const handleUpdate = (e: React.MouseEvent) => {
    e.stopPropagation();
    let data_to_send = {
      stageId: Number(stage_input),
      vehicleId: item.vehicleId,
    };
    let body = JSON.stringify(data_to_send);
    if (token_id) {
      customFetchWithToken(
        update_stage_url,
        token_id,
        { method: "POST" },
        body
      ).then((response) => {
        showToastMessage(response.SUCCESS, `Stage is updated`);
        setedit(false);
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="w-full rounded bg-white  ">
        <div
          className=" p-4 border-b border-[#00aa13] cursor-pointer"
          onClick={() => setflag(!flag)}
        >
          {/* checkbox-container  */}
          <div className="w-full flex-auto flex justify-evenly items-center text-black lg:flex-nowrap flex-wrap gap-y-8">
            <div className="form-check w-5  flex-auto">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#00aa13] checked:border-[#00aa13] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
            </div>
            {/* name */}
            <div className=" flex flex-col w-32 flex-auto">
              <span>Name</span>
              <span>{item.RiderName}</span>
            </div>
            {/* timing  */}
            <div className="flex-auto flex flex-col w-32">
              <span>Number</span>
              <span>{item.MobileNumber}</span>
            </div>
            {/* email  */}
            <div className=" flex-auto flex flex-col w-32">
              <span>Regitration </span>
              <span>{item.Registration_No}</span>
            </div>
            {/* code  */}
            <div className="flex-auto flex flex-col w-32">
              <span>Payable Amount</span>
              <span>{total_amt}</span>
            </div>
            {!edit ? (
              <div className="flex-auto flex flex-col w-32">
                <div className="flex gap-2 items-center">
                  <span>Status</span>
                  <Tooltip title="edit status">
                    <EditIcon color="success" onClick={handelEdit} />
                  </Tooltip>
                </div>
                <span>{item.Stage_Name}</span>
              </div>
            ) : (
              <div className="flex-auto w-32">
                <Box
                  sx={{
                    minWidth: 150,
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <GroupSelect setstage_input={setstage_input} />
                  <Tooltip title="cancel">
                    <CancelIcon color="error" onClick={handelCancel} />
                  </Tooltip>
                  <Tooltip title="confirm">
                    <CheckCircleIcon color="success" onClick={handleUpdate} />
                  </Tooltip>
                </Box>
              </div>
            )}

            <div className="flex-none text-center w-10 ">
              <i
                className={`fa-solid fa-chevron-down ${flag && "rotate-180"}`}
              ></i>
            </div>
          </div>
        </div>
        {flag && (
          <div className="mt-2">
            <div>
              <div className="accordion-body py-4 px-5 flex flex-col gap-8 ">
                <div className="flex flex-col">
                  <div className="flex justify-around lg:flex-nowrap flex-wrap gap-y-8">
                    {ques_row1.map((item) => {
                      return (
                        <div className="flex flex-col gap-2 w-48">
                          <span className="text-center">{item.Question}</span>
                          <span className="text-center">
                            {item.Response} ({item.Cost})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-around lg:flex-nowrap flex-wrap gap-y-8">
                    {ques_row2.map((item) => {
                      return (
                        <div className="flex flex-col w-48 gap-2 ">
                          <span className="text-center">{item.Question}</span>
                          <span className="text-center">
                            {item.Response} ({item.Cost})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-around lg:flex-nowrap flex-wrap gap-y-8">
                    {ques_row3.map((item) => {
                      return (
                        <div className="flex flex-col w-48 gap-2">
                          <span className="text-center">{item.Question}</span>
                          <span className="text-center">
                            {item.Response}({item.Cost})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-around lg:flex-nowrap flex-wrap gap-y-8">
                    {ques_row4.map((item) => {
                      return (
                        <div className="flex flex-col w-48 gap-2">
                          <span className="text-center">{item.Question}</span>
                          <span className="text-center">
                            {item.Response}({item.Cost})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairingAccordion;
