import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { RootState } from "../../../../app/store";
import { useSelector } from "react-redux";
import { repair_stage_url } from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import {
  repair_stage_menu_api_response,
  repair_stage_menu_datatype,
} from "../../../../models/API_data";
interface stage_options_menu_type {
  label: string;
  id: number;
  groupName: string | null;
}
interface groupSelectProps {
  setstage_input: Function;
}

export default function GroupSelect({ setstage_input }: groupSelectProps) {
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const [repair_stage_menu, setrepair_stage_menu] = useState<
    [] | stage_options_menu_type[]
  >([]);
  useEffect(() => {
    if (token_id) {
      let url = `${repair_stage_url}eligibility inspection`;
      customFetchWithToken(url, token_id ?? "", {
        method: "GET",
      }).then((data: repair_stage_menu_api_response) => {
        const options = data.DATA.map((item) => {
          let groupName = null;
          if (Number(item.Stage_Parent_Id) === 105) {
            groupName = "Pending Reasons";
          } else if (Number(item.Stage_Parent_Id) === 103) {
            groupName = "Cancel Reasons";
          }
          return {
            label: item.Stage_Name,
            id: item.Stage_Master_Id,
            groupName: groupName,
          };
        });
        setrepair_stage_menu([...options]);
      });
    }
  }, [token_id]);

  return (
    <Autocomplete
      id="grouped-demo"
      options={repair_stage_menu}
      groupBy={(option) => option.groupName ?? ""}
      getOptionLabel={(option) => option.label}
      sx={{ width: 300 }}
      onChange={(e, newValue) => setstage_input(newValue?.id)}
      renderInput={(params) => (
        <TextField {...params} label="With categories" />
      )}
    />
  );
}
