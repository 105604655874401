import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Textarea } from "@material-tailwind/react";
import { opentoaddData } from "../../../models/lms_data_api";
import customFetch from "../../../API/CustomFetch";
import customFetchWithToken from "../../../API/CustomFetchWtihToken";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { formDropdownPayload } from "../../../features/lms_actions/lmsSlice";
import {
  lms_get_gender_drp_api,
  lms_get_profession_drp_api,
  lms_lead_type_dropdown_api,
  lms_leadsource_dropdown_url,
  lms_leadstatus_drop_menu,
  lms_state_drop_menu,
  lms_vehicle_brand_menu,
  wheel_type_dropdown_api,
} from "../../../API/api";
import {
  baseUrl,
  lms_add_lead,
  lms_update_lead,
  vehicle_profile_add_api,
} from "../../../API/api";
import {
  lms_modal_ref_menu_type,
  lms_city_menu_type,
  lead_type_dropdown_type,
} from "../../../models/API_data";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer } from "react-toastify";
import showToastMessage from "../../../Tostify/Index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import Loader from "../../../Global_Component/loader/loader";
import {
  setLmsData,
  updateLmsData,
  setLmsFromDropdown,
} from "../../../features/lms_actions/lmsSlice";
import { Transition } from "@headlessui/react";
import { resolve } from "path";

type FormProp = {
  setOpentoAdd: Function;
  opentoAdd: opentoaddData;
};
interface vehicle_profile_body_type {
  City_Id: string | undefined;
  ManufacturingYear: string | undefined;
  Reg_No: string | undefined;
  Model_Id: string | undefined;
  Rear_Whee_ld: string | undefined;
  Inspection_Date: string | undefined | Dayjs;
}
const startDate = new Date();
const endDate = new Date();
startDate.setDate(startDate.getDate() - 1);
endDate.setDate(endDate.getDate() + 100);
const disableDates = (date: Date) => {
  return date < startDate || date > endDate;
};

interface initialValuesTypes {
  LeadTypeId: string;
  LeadName: string | undefined;
  LeadStatus: string | undefined;
  LeadSourceId: string | undefined;
  MobileNumber: string | undefined;
  LeadStatusExtended: string | undefined;
  NextFollowUp: string | undefined | Dayjs;
  WhatsAppNo: string | undefined;
  Gender: string | undefined;
  DateOfBirth: string | undefined | Dayjs;
  InspectionDate: string | undefined | Dayjs;
  Profession: string | undefined;
  BookedAmount: string | undefined;
  SellingPrice: string | undefined;
  AnnualIncome: string | undefined;
  Rear_Whee_ld: string | undefined;
  Email: string | undefined;
  VehicleRegistrationNumber: string | undefined;
  MfgYr: string | undefined;
  vehicleBrand: string | undefined;
  VehicleModelId: string | undefined;
  state: string | undefined;
  CityId: string | undefined;
  Comments: string;
}

const LeadSchema = Yup.object().shape({
  LeadTypeId: Yup.string().required("Please select lead type"),
  LeadName: Yup.string()
    .required("Please enter a lead name")
    .matches(/^[A-Za-z ]+$/, "Lead name can only contain letters and spaces"),
  LeadSourceId: Yup.string().required("Please select a lead source"),
  LeadStatus: Yup.string().required("Please select a lead status"),
  MobileNumber: Yup.string()
    .required("Please enter a phone number")
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
  WhatsAppNo: Yup.string().matches(
    /^[0-9]{10}$/,
    "Whats app number must be exactly 10 digits"
  ),
  LeadStatusExtended: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "11" || s === "15" || s === "14",
    then: (s: Yup.Schema) =>
      Yup.string().required("field is required date is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  NextFollowUp: Yup.string().when("LeadStatus", {
    is: (s: string) => s === "11",
    then: (s: Yup.Schema) =>
      Yup.date()
        .required("follow up date is required")
        .min(dayjs(), "invalid date"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  Gender: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) => Yup.string().required("Gender is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  DateOfBirth: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) => Yup.date().required("DateOfBirth is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  InspectionDate: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.date().required("Inspection Date  is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  Profession: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) => Yup.string().required("Profession is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  AnnualIncome: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string()
        .required("annaul income is required")
        .matches(/^[0-9]+$/, "Must be only numbers"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  BookedAmount: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string()
        .required("Booking Amount is required")
        .matches(/^[0-9]+$/, "Must be only numbers"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  SellingPrice: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string()
        .required("Selling Price is required")
        .matches(/^[0-9]+$/, "Must be only numbers"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  Email: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string().required("Email is required").email("Invalid Email"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  Rear_Whee_ld: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) => Yup.string().required("wheel type is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  VehicleRegistrationNumber: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string()
        .required("vehicle registration is required")
        .matches(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  MfgYr: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string()
        .required("mfg yr is required")
        .matches(/^[0-9]{4}$/, "Must be exactly 4 digits"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  vehicleBrand: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) =>
      Yup.string().required("vehicle brand  is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  VehicleModelId: Yup.string().when("vehicleBrand", {
    is: (s: string) => s,
    then: (s: Yup.Schema) => Yup.string().required("vehicle model is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  state: Yup.string().when("LeadStatus", {
    is: (s: string) => s == "14",
    then: (s: Yup.Schema) => Yup.string().required("state is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  CityId: Yup.string().when("state", {
    is: (s: string) => s,
    then: (s: Yup.Schema) => Yup.string().required("CityId is required"),
    otherwise: (s: Yup.Schema) => Yup.string(),
  }),
  Comments: Yup.string(),
});
function LmsForm({ setOpentoAdd, opentoAdd }: FormProp) {
  //accodion states
  const [show_acc1, setshow_acc1] = useState(false);
  const [show_acc2, setshow_acc2] = useState(false);

  let today = dayjs();
  const {
    lms: {
      lmsData: { all },
      lmsForm: {
        dropDownData: {
          leadSource,
          leadStatus,
          vehiclBrand,
          state,
          wheelType,
          gender,
          profession,
        },
        loader,
      },
    },
    user: { token_id },
  } = useSelector(
    (state: RootState) => state,
    (prev, next) => prev.lms.lmsForm.loader === next.lms.lmsForm.loader
  );
  const dispatch = useDispatch();

  const [Modal_ref_brand_drop_menu, setModal_ref_brand_drop_menu] = useState<
    [] | lms_modal_ref_menu_type[]
  >([]);

  const [CityId_ref_state_menu, setCityId_ref_state_menu] = useState<
    [] | lms_city_menu_type[]
  >([]);

  const [check_input, setcheck_input] = useState(false);
  const [lead_type, setlead_type] = useState<lead_type_dropdown_type[]>([]);

  const apis = [
    lms_leadsource_dropdown_url,
    lms_leadstatus_drop_menu,
    lms_vehicle_brand_menu,
    lms_state_drop_menu,
    wheel_type_dropdown_api,
    lms_get_profession_drp_api,
    lms_get_gender_drp_api,
  ];
  useEffect(() => {
    const dropdownsDATA: formDropdownPayload = {
      leadSource: null,
      leadStatus: null,
      vehiclBrand: null,
      state: null,
      wheelType: null,
      profession: null,
      gender: null,
    };
    if (token_id && loader) {
      const fetches = apis.map((api) => customFetchWithToken(api, token_id));
      Promise.all(fetches)
        .then((responses) => {
          const [
            leadSource,
            leadStatus,
            vehiclBrand,
            state,
            wheelType,
            profession,
            gender,
          ] = responses.map((response) => [...response.DATA]);
          dropdownsDATA.leadSource = leadSource;
          dropdownsDATA.leadStatus = leadStatus;
          dropdownsDATA.vehiclBrand = vehiclBrand;
          dropdownsDATA.state = state;
          dropdownsDATA.wheelType = wheelType;
          dropdownsDATA.profession = profession;
          dropdownsDATA.gender = gender;
        })
        .then(() => dispatch(setLmsFromDropdown(dropdownsDATA)))
        .catch((response) => {
          showToastMessage(response.SUCCESS, response.MESSAGE);
        });
    }
    customFetchWithToken(lms_lead_type_dropdown_api, token_id ?? "").then(
      (data) => setlead_type(data.DATA)
    );
  }, []);

  useEffect(() => {
    if (opentoAdd.update) {
      formik.setFieldValue("LeadName", opentoAdd.update?.LeadName);
      formik.setFieldValue(
        "LeadSourceId",
        opentoAdd.update?.LeadSourceId?.toString()
      );
      formik.setFieldValue("MobileNumber", opentoAdd.update?.MobileNumber);
      formik.setFieldValue("WhatsAppNo", opentoAdd.update?.WhatsAppNo ?? "");
      formik.setFieldValue("Gender", opentoAdd.update?.GenderId?.toString());
      formik.setFieldValue("LeadTypeId", opentoAdd.update.LeadTypeId);

      formik.values.Gender = opentoAdd.update?.GenderId?.toString();
      formik.values.AnnualIncome = opentoAdd.update?.AnnualIncome?.toString();
      formik.values.DateOfBirth = dayjs(opentoAdd?.update?.AgeGroup);
      formik.values.Profession = opentoAdd.update?.ProfessionId?.toString();
      formik.values.Email = opentoAdd.update?.Email ?? "";
      formik.values.VehicleRegistrationNumber =
        opentoAdd.update?.VehicleRegistrationNumber ?? "";
      formik.setFieldValue(
        "vehicleBrand",
        opentoAdd.update?.Brand_Id?.toString() ?? ""
      );

      formik.values.MfgYr = opentoAdd.update?.MfgYr?.toString();
      formik.setFieldValue(
        "state",
        opentoAdd.update?.State_Id?.toString() ?? ""
      );

      formik.values.CityId = opentoAdd.update?.CityId?.toString();

      formik.values.Comments = opentoAdd.update?.Comments ?? "";
      if (
        (Number(opentoAdd.update?.LeadStatus) >= 12 &&
          Number(opentoAdd.update?.LeadStatus) <= 16 &&
          !(Number(opentoAdd.update?.LeadStatus) === 15)) ||
        Number(opentoAdd.update?.LeadStatus) === 10
      ) {
        formik.values.LeadStatus = opentoAdd.update?.LeadStatus?.toString();
        formik.values.LeadStatusExtended = "";
      } else if (
        (Number(opentoAdd.update?.LeadStatus) > 18 &&
          Number(opentoAdd.update?.LeadStatus) <= 24) ||
        Number(opentoAdd.update?.LeadStatus) === 31 ||
        Number(opentoAdd.update?.LeadStatus) === 43
      ) {
        formik.values.LeadStatus = "11";
        formik.values.LeadStatusExtended =
          opentoAdd.update?.LeadStatus?.toString();
        formik.values.NextFollowUp = dayjs(opentoAdd?.update?.NextFollowUp);
      } else if (
        (Number(opentoAdd.update?.LeadStatus) > 24 &&
          Number(opentoAdd.update?.LeadStatus) <= 30) ||
        Number(opentoAdd.update?.LeadStatus) === 31
      ) {
        formik.values.LeadStatus = "15";
        formik.values.LeadStatusExtended =
          opentoAdd.update?.LeadStatus?.toString();
      }
    }
  }, [opentoAdd.update]);
  useEffect(() => {
    if (opentoAdd?.update?.Brand_Id) {
      formik.values.VehicleModelId =
        opentoAdd?.update?.Vehicle_Model_Id?.toString();
    }
    if (opentoAdd?.update?.State_Id) {
      formik.values.CityId = opentoAdd?.update?.CityId?.toString();
    }
  }, [Modal_ref_brand_drop_menu, CityId_ref_state_menu]);

  const create_vehicle_profile = async (
    body_object: vehicle_profile_body_type
  ) => {
    let body = JSON.stringify(body_object);
    let response = await customFetchWithToken(
      vehicle_profile_add_api,
      token_id ?? "",
      { method: "POST" },
      body
    );
    return response;
  };

  const initialValues: initialValuesTypes = {
    LeadTypeId: "",
    LeadName: "",
    LeadStatus: "",
    LeadSourceId: "",
    MobileNumber: "",
    LeadStatusExtended: "",
    WhatsAppNo: "",
    NextFollowUp: "",
    Gender: "",
    DateOfBirth: "",
    InspectionDate: "",
    Profession: "",
    AnnualIncome: "",
    BookedAmount: "",
    SellingPrice: "",
    Email: "",
    Rear_Whee_ld: "",
    VehicleRegistrationNumber: "",
    MfgYr: "",
    vehicleBrand: "",
    VehicleModelId: "",
    state: "",
    CityId: "",
    Comments: "",
  };
  const won_sub_stages = [106, 108, 109, 14];
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LeadSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      // creating vechicle profile
      let insert_id: null | number = null;

      if (won_sub_stages.includes(Number(formik.values.LeadStatus))) {
        let body = {
          City_Id: formik.values.CityId,
          ManufacturingYear: formik.values.MfgYr,
          Reg_No: formik.values.VehicleRegistrationNumber,
          Model_Id: formik.values.VehicleModelId,
          Rear_Whee_ld: formik.values.Rear_Whee_ld,
          Inspection_Date: formik.values.InspectionDate,
        };
        let response = await create_vehicle_profile(body);
        insert_id = response.DATA.insertId;
      }

      let data_to_send: any = {
        ...values,
        LeadStatus: formik.values.LeadStatusExtended
          ? formik.values.LeadStatusExtended
          : formik.values.LeadStatus,
        WhatsAppNo: !check_input
          ? formik.values.WhatsAppNo
          : formik.values.MobileNumber,
      };
      delete data_to_send.LeadStatusExtended; //delete unnecessary key

      if (insert_id) {
        data_to_send.VehicleProfile = insert_id;
      }

      // making body
      let body = JSON.stringify(data_to_send);
      // api calls
      if (opentoAdd.add) {
        customFetchWithToken(
          lms_add_lead,
          token_id ?? "",
          { method: "POST" },
          body
        )
          .then((data) => {
            if (all) {
              dispatch(setLmsData({ data: data.DATA, leadType: [2] }));
              showToastMessage(data.SUCCESS, data.MESSAGE);
              setSubmitting(false);
            }
          })
          .catch((error) => {
            showToastMessage(false, `${error}`);
          });
      } else {
        let update_url = `${lms_update_lead}${opentoAdd?.update?.LeadId}`;
        customFetchWithToken(
          update_url,
          token_id ?? "",
          { method: "POST" },
          body
        ).then((data) => {
          dispatch(updateLmsData(data.DATA[0]));
          showToastMessage(data.SUCCESS, data.MESSAGE);
          setSubmitting(false);
        });
      }
      resetForm();
      setOpentoAdd({
        flag: true,
        update: null,
        add: false,
      });
    },
  });
  useEffect(() => {
    if (formik.values.state) {
      let CityId_url = `${baseUrl}/state/v1/get/${formik.values.state}/city`;
      customFetch(CityId_url).then((data) =>
        setCityId_ref_state_menu([...data.DATA])
      );
    }
  }, [formik.values.state]);
  useEffect(() => {
    if (formik.values.vehicleBrand) {
      let modal_url = `${baseUrl}/vehicle-brand/v1/${formik.values.vehicleBrand}/model`;
      customFetch(modal_url).then((data) =>
        setModal_ref_brand_drop_menu([...data.DATA])
      );
    }
  }, [formik.values.vehicleBrand]);
  if (loader)
    return (
      <div className="w-full h-full flex justify-center items-center ">
        <Loader />
      </div>
    );
  return (
    <div className="w-full flex flex-col bg-white gap-6 p-3 md:mb-[6rem] mb-[3rem]   ">
      <ToastContainer />
      <form
        className="flex flex-col gap-6  px-4 "
        onSubmit={formik.handleSubmit}
      >
        {/* important fields  */}
        <div id="important-fields section-1" className="flex flex-col gap-6">
          <div className="flex flex-col gap-5 ">
            <div className="flex justify-between">
              <span className="text-gray-600">
                Section 1 (Basic details){" "}
                <span className="text-red-600">*</span>
              </span>
              <i
                className="fa-solid fa-square-xmark text-[26px] cursor-pointer ml-auto"
                style={{ color: "#00aa13" }}
                onClick={() => {
                  setOpentoAdd({
                    ...opentoAdd,
                    flag: true,
                    add: false,
                    update: "",
                  });
                }}
              ></i>
            </div>
          </div>
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={
                formik.touched.LeadSourceId &&
                Boolean(formik.errors.LeadSourceId)
              }
            >
              <InputLabel id="demo-simple-select-label" color="success">
                Lead Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="LeadTypeId"
                id="LeadTypeId"
                color="success"
                label="Lead Type"
                value={formik.values.LeadTypeId}
                onChange={formik.handleChange}
              >
                {lead_type?.map((item, i) => {
                  return (
                    <MenuItem value={item.TypeMasterId} key={i}>
                      {item.TypeName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {formik.touched.LeadTypeId && formik.errors.LeadTypeId}
              </FormHelperText>
            </FormControl>
          </Box>
          <TextField
            name="LeadName"
            id="LeadName"
            label="Lead Name"
            color="success"
            variant="outlined"
            value={formik.values.LeadName}
            onChange={formik.handleChange}
            error={formik.touched.LeadName && Boolean(formik.errors.LeadName)}
            helperText={formik.touched.LeadName && formik.errors.LeadName}
          />
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={
                formik.touched.LeadSourceId &&
                Boolean(formik.errors.LeadSourceId)
              }
            >
              <InputLabel id="demo-simple-select-label" color="success">
                Lead Source
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="LeadSourceId"
                id="LeadSourceId"
                color="success"
                label="Lead Source"
                value={formik.values.LeadSourceId}
                onChange={formik.handleChange}
              >
                {leadSource?.map((item, i) => {
                  return (
                    <MenuItem value={item.LeadSourceId} key={i}>
                      {item.Source_Name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {formik.touched.LeadSourceId && formik.errors.LeadSourceId}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 120 }}>
            <FormControl
              fullWidth
              error={
                formik.touched.LeadStatus && Boolean(formik.errors.LeadStatus)
              }
            >
              <InputLabel id="demo-simple-select-label" color="success">
                Lead Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="LeadStatus"
                id="LeadStatus"
                color="success"
                label="Lead Status"
                value={formik.values.LeadStatus}
                onChange={formik.handleChange}
              >
                {leadStatus?.map((item, i) => (
                  <MenuItem
                    value={item.id}
                    key={i}
                    disabled={Number(item.id) === 16}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.LeadStatus && formik.errors.LeadStatus}
              </FormHelperText>
            </FormControl>
          </Box>

          {leadStatus?.map((item) => {
            if (
              item.id === Number(formik.values.LeadStatus) &&
              item.sub_lebel.length > 0
            ) {
              return (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.LeadStatusExtended &&
                      Boolean(formik.errors.LeadStatusExtended)
                    }
                  >
                    <InputLabel id="demo-simple-select-label" color="success">
                      {item.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="LeadStatusExtended"
                      id="LeadStatusExtended"
                      color="success"
                      label={item.label}
                      value={formik.values.LeadStatusExtended}
                      onChange={formik.handleChange}
                    >
                      {item.sub_lebel.map((item, i) => (
                        <MenuItem value={item.id} key={i}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.LeadStatusExtended &&
                        formik.errors.LeadStatusExtended}
                    </FormHelperText>
                  </FormControl>
                </Box>
              );
            }
          })}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            sx={{ minWidth: 250 }}
          >
            <DatePicker
              label="Birth Date"
              value={formik.values.DateOfBirth}
              onChange={(date) => formik.setFieldValue("DateOfBirth", date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="success"
                  id="DateOfBirth"
                  name="DateOfBirth"
                  error={
                    formik.touched.DateOfBirth &&
                    Boolean(formik.errors.DateOfBirth)
                  }
                />
              )}
            />
          </LocalizationProvider>
          {formik.values.LeadStatusExtended &&
            Number(formik.values.LeadStatus) === 11 && (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ minWidth: 250 }}
              >
                <DatePicker
                  label="Follow Up Date"
                  minDate={today}
                  value={formik.values.NextFollowUp}
                  onChange={(date) =>
                    formik.setFieldValue("NextFollowUp", date)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="success"
                      id="NextFollowUp"
                      name="NextFollowUp"
                      error={
                        formik.touched.NextFollowUp &&
                        Boolean(formik.errors.NextFollowUp)
                      }
                      helperText={
                        formik.touched.NextFollowUp &&
                        formik.errors.NextFollowUp
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          {Number(formik.values.LeadStatus) === 14 && (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ minWidth: 250 }}
            >
              <DatePicker
                label="Inspection Date"
                value={formik.values.InspectionDate}
                shouldDisableDate={disableDates}
                onChange={(date) =>
                  formik.setFieldValue("InspectionDate", date)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    id="InspectionDate"
                    name="InspectionDate"
                    error={
                      formik.touched.InspectionDate &&
                      Boolean(formik.errors.InspectionDate)
                    }
                    helperText={
                      formik.touched.InspectionDate &&
                      formik.errors.InspectionDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
          <TextField
            name="MobileNumber"
            id="MobileNumber"
            label="Mobile Number"
            color="success"
            variant="outlined"
            disabled={!!opentoAdd.update}
            value={formik.values.MobileNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.MobileNumber && Boolean(formik.errors.MobileNumber)
            }
            helperText={
              formik.touched.MobileNumber && formik.errors.MobileNumber
            }
          />
          <div className="form-check">
            <label htmlFor="whatsApp">Same as Mobile Number</label>
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#00aa13] checked:border-[#00aa13] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              id="whatsApp"
              checked={check_input}
              onChange={(e) => setcheck_input(e.target.checked)}
            />
          </div>
          {!check_input && (
            <TextField
              id="WhatsAppNo"
              name="WhatsAppNo"
              label="What's App Number"
              color="success"
              variant="outlined"
              value={formik.values.WhatsAppNo}
              onChange={formik.handleChange}
              error={
                formik.touched.WhatsAppNo && Boolean(formik.errors.WhatsAppNo)
              }
              helperText={formik.touched.WhatsAppNo && formik.errors.WhatsAppNo}
            />
          )}
        </div>
        <Divider />

        <div id="section-2 ">
          <div
            id="Accordion-head"
            className="flex justify-between  w-full  items-center  cursor-pointer "
            onClick={() => setshow_acc1(!show_acc1)}
          >
            <span
              className={`text-gray-600 ${
                Number(formik.values.LeadStatus) === 14
                  ? "text-red-600"
                  : !show_acc1
                  ? "text-gray-600"
                  : "text-[#00aa13]"
              }`}
            >
              Section 2 (Personal details)
            </span>
            <div className={`${show_acc1 ? "block " : "hidden"} `}>
              <span>
                <i className="fa-solid fa-minus"></i>
              </span>
            </div>
            <div className={`${!show_acc1 ? "block " : "hidden "} `}>
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>
            </div>
          </div>
          <Transition.Root as={Fragment} show={show_acc1}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100 "
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 "
            >
              <div id="Accordion-body" className="flex flex-col gap-4 mt-4 ">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.Gender && Boolean(formik.errors.Gender)
                    }
                  >
                    <InputLabel id="demo-simple-select-label" color="success">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="Gender"
                      name="Gender"
                      color="success"
                      label="Gender"
                      value={formik.values.Gender}
                      onChange={formik.handleChange}
                    >
                      {gender?.map((item) => {
                        return (
                          <MenuItem
                            value={item.GenderId.toString()}
                            key={item.GenderId}
                          >
                            {item.Gender}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.Gender && formik.errors.Gender}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.Profession &&
                      Boolean(formik.errors.Profession)
                    }
                  >
                    <InputLabel id="demo-simple-select-label" color="success">
                      Profession
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select"
                      id="Profession"
                      name="Profession"
                      color="success"
                      label="Profession"
                      value={formik.values.Profession}
                      onChange={formik.handleChange}
                    >
                      {profession?.map((item, i) => {
                        return (
                          <MenuItem
                            value={`${item.ProfessionId}`}
                            key={item.ProfessionId}
                          >
                            {item.Profession}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {formik.touched.Profession && formik.errors.Profession}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <TextField
                  id="AnnualIncome"
                  name="AnnualIncome"
                  label="Annual Income"
                  color="success"
                  variant="outlined"
                  value={formik.values.AnnualIncome}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.AnnualIncome &&
                    Boolean(formik.errors.AnnualIncome)
                  }
                  helperText={
                    formik.touched.AnnualIncome && formik.errors.AnnualIncome
                  }
                />
                <TextField
                  id="Email"
                  name="Email"
                  label="Email"
                  variant="outlined"
                  color="success"
                  type="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  error={formik.touched.Email && Boolean(formik.errors.Email)}
                  helperText={formik.touched.Email && formik.errors.Email}
                />
              </div>
            </Transition.Child>
          </Transition.Root>
        </div>

        <div id="section-2 ">
          <div
            id="Accordion-head"
            className="flex justify-between  w-full  items-center  cursor-pointer "
            onClick={() => setshow_acc2(!show_acc2)}
          >
            <span
              className={`text-gray-600 ${
                Number(formik.values.LeadStatus) === 14
                  ? "text-red-600"
                  : !show_acc2
                  ? "text-gray-600"
                  : "text-[#00aa13]"
              }`}
            >
              Section 3 (Vehicle details)
            </span>
            <div className={`${show_acc2 ? "block " : "hidden"} `}>
              <span>
                <i className="fa-solid fa-minus"></i>
              </span>
            </div>
            <div className={`${!show_acc2 ? "block " : "hidden "} `}>
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>
            </div>
          </div>
          <Transition.Root as={Fragment} show={show_acc2}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo=" opacity-100 "
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100 "
              leaveTo="opacity-0"
            >
              <div id="Accordion-body" className="flex flex-col gap-4 mt-4 ">
                <div className="flex gap-2">
                  <div className="flex flex-col gap-2">
                    <TextField
                      id="vehiclReg"
                      name="VehicleRegistrationNumber"
                      label="Vehicle Reg"
                      variant="outlined"
                      color="success"
                      value={formik.values.VehicleRegistrationNumber}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.VehicleRegistrationNumber &&
                        Boolean(formik.errors.VehicleRegistrationNumber)
                      }
                      helperText={
                        formik.touched.VehicleRegistrationNumber &&
                        formik.errors.VehicleRegistrationNumber
                      }
                    />
                  </div>

                  <TextField
                    id="MfgYr"
                    name="MfgYr"
                    label="Vehicle-Mfg-yr"
                    variant="outlined"
                    color="success"
                    value={formik.values.MfgYr}
                    onChange={formik.handleChange}
                    error={formik.touched.MfgYr && Boolean(formik.errors.MfgYr)}
                    helperText={formik.touched.MfgYr && formik.errors.MfgYr}
                  />
                </div>
                {Number(formik.values.LeadStatus) === 14 && (
                  <>
                    <TextField
                      name="SellingPrice"
                      id="SellingPrice"
                      label="Selling Price"
                      color="success"
                      variant="outlined"
                      value={formik.values.SellingPrice}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.SellingPrice &&
                        Boolean(formik.errors.SellingPrice)
                      }
                      helperText={
                        formik.touched.SellingPrice &&
                        formik.errors.SellingPrice
                      }
                    />
                    <TextField
                      name="BookedAmount"
                      id="BookedAmount"
                      label="Booked Amount"
                      color="success"
                      variant="outlined"
                      value={formik.values.BookedAmount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.MobileNumber &&
                        Boolean(formik.errors.BookedAmount)
                      }
                      helperText={
                        formik.touched.BookedAmount &&
                        formik.errors.BookedAmount
                      }
                    />
                  </>
                )}
                <div className="flex gap-2">
                  <Box sx={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.vehicleBrand &&
                        Boolean(formik.errors.vehicleBrand)
                      }
                    >
                      <InputLabel id="demo-simple-select-label" color="success">
                        Vehicle Brand
                      </InputLabel>
                      <Select
                        labelId="vehicleBrand"
                        name="vehicleBrand"
                        id="demo-simple-select"
                        color="success"
                        label="Vehicle Brand"
                        value={formik.values.vehicleBrand}
                        onChange={formik.handleChange}
                      >
                        {vehiclBrand?.map((item, i) => (
                          <MenuItem value={item.BrandId} key={i}>
                            {item.Brand}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.vehicleBrand &&
                          formik.errors.vehicleBrand}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {formik.values.vehicleBrand && (
                    <Box sx={{ width: "100%" }}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.VehicleModelId &&
                          Boolean(formik.errors.VehicleModelId)
                        }
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          color="success"
                        >
                          Vehicle Model
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="VehicleModelId"
                          name="VehicleModelId"
                          color="success"
                          label="Vehicle Model"
                          value={formik.values.VehicleModelId}
                          onChange={formik.handleChange}
                        >
                          {Modal_ref_brand_drop_menu.map((item, i) => (
                            <MenuItem value={item.ModelId} key={i}>
                              {item.Model}
                              {item.Variant && `- ${item.Variant}`}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.VehicleModelId &&
                            formik.errors.VehicleModelId}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </div>

                {Number(formik.values.LeadStatus) === 14 && (
                  <Box sx={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.Rear_Whee_ld &&
                        Boolean(formik.errors.Rear_Whee_ld)
                      }
                    >
                      <InputLabel id="demo-simple-select-label" color="success">
                        Wheel Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="Rear_Whee_ld"
                        name="Rear_Whee_ld"
                        color="success"
                        label="Wheel Type"
                        value={formik.values.Rear_Whee_ld}
                        onChange={formik.handleChange}
                      >
                        {wheelType?.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.Wheel_type}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.Rear_Whee_ld &&
                          formik.errors.Rear_Whee_ld}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                )}
                <div className="flex gap-2">
                  <Box sx={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                    >
                      <InputLabel id="demo-simple-select-label" color="success">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="state"
                        name="state"
                        color="success"
                        label="State"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                      >
                        {state?.map((item, i) => (
                          <MenuItem value={item.Id} key={i}>
                            {item.State}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.state && formik.errors.state}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  {formik.values.state && (
                    <Box sx={{ width: "100%" }}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.CityId && Boolean(formik.errors.CityId)
                        }
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          color="success"
                        >
                          CityId
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="CityId"
                          name="CityId"
                          color="success"
                          label="CityId"
                          value={formik.values.CityId}
                          onChange={formik.handleChange}
                        >
                          {CityId_ref_state_menu.map((item, i) => (
                            <MenuItem value={item.Id} key={i}>
                              {item.City}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.CityId && formik.errors.CityId}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </div>
                <div className="w-full">
                  <Textarea
                    id="Comments"
                    name="Comments"
                    label="Message"
                    value={formik.values.Comments}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.Comments && Boolean(formik.errors.Comments)
                    }
                  />
                </div>
              </div>
            </Transition.Child>
          </Transition.Root>
        </div>

        {opentoAdd.add ? (
          <div className=" bottom-0  fixed  right-0 lg:w-[450px] w-full  ">
            <button
              className="inline-block w-full p-4 bg-[#00aa13] text-white font-medium text-2xl leading-tight uppercase  shadow-md hover:bg-[#026e0f] hover:shadow-lg focus:bg-[#026e0f] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#026e0f] active:shadow-lg transition duration-150 ease-in-out"
              type="submit"
            >
              SAVE
            </button>
          </div>
        ) : (
          <div className=" bottom-0  fixed  right-0 lg:w-[450px] w-full  ">
            <button
              className="inline-block w-full p-4 bg-[#00aa13] text-white font-medium text-2xl leading-tight uppercase  shadow-md hover:bg-[#026e0f] hover:shadow-lg focus:bg-[#026e0f] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#026e0f] active:shadow-lg transition duration-150 ease-in-out"
              type="submit"
            >
              UPDATE
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
export default React.memo(LmsForm);
